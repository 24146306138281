import { createContext, useContext } from 'react';

type ResumeVerb = 'resume' | 'cv';

const ResumeVerbContext = createContext<ResumeVerb>('resume');

export function ResumeVerbProvider({
  children,
  resumeVerb,
}: {
  children: React.ReactNode;
  resumeVerb?: ResumeVerb;
}) {
  return (
    <ResumeVerbContext.Provider value={resumeVerb || 'resume'}>
      {children}
    </ResumeVerbContext.Provider>
  );
}

export function useResumeVerb() {
  const resumeVerb = useContext(ResumeVerbContext);

  if (resumeVerb === 'resume') {
    return {
      rVerb: 'resume',
      rVerbPluarl: 'resumes',
      rVerbCap: 'Resume',
      rVerbPluarlCap: 'Resumes',
      rVerbExp: 'resume',
      rVerbExpPluarl: 'resumes',
      rVerbExpCap: 'Resume',
      rVerbExpPluarlCap: 'Resumes',
    };
  } else {
    return {
      rVerb: 'CV',
      rVerbPluarl: 'CVs',
      rVerbCap: 'CV',
      rVerbPluarlCap: 'CVs',
      rVerbExp: 'curriculum vitae',
      rVerbExpPluarl: 'curriculum vitaes',
      rVerbExpCap: 'Curriculum vitae',
      rVerbExpPluarlCap: 'Curriculum vitaes',
    };
  }
}
