import { Global } from '@emotion/core';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import Dark from 'components/ui/Dark';

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
  isDark?: boolean;
  CustomHeader?: React.ReactNode;
  CustomFooter?: React.ReactNode;
};

export default function Page({
  children,
  backgroundColor,
  isDark,
  CustomHeader,
  CustomFooter,
}: Props) {
  function Content() {
    return (
      <>
        {CustomHeader ? CustomHeader : <Header />}
        <main>{children}</main>
        {CustomFooter ? CustomFooter : <Footer />}
        {backgroundColor && (
          <Global
            styles={(theme) => ({
              body: {
                backgroundColor:
                  theme.colors[backgroundColor] || backgroundColor,
              },
            })}
          />
        )}
      </>
    );
  }
  if (isDark) {
    return (
      <Dark>
        <Content />
      </Dark>
    );
  }
  return <Content />;
}
