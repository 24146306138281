import { Box, Text } from 'theme-ui';
import { Link } from 'components/ui/links';
import Logo from 'components/ui/Logo';

export default function Footer() {
  const headerStyle = {
    fontWeight: 'bold',
    color: 'textLight',
    fontSize: 15,
    marginBottom: [16, 24],
    marginTop: [32, 64, 0],
  };
  const linkStyle = {
    color: 'textLighter',
    fontSize: [13, 14, 15],
    marginBottom: [16, 24],
    ':hover': {
      color: 'text',
    },
  };
  return (
    <Box as="footer">
      <Box as="nav" variant="grids.fullPadded" sx={{ py: 64 }}>
        <Box
          sx={{
            gridColumn: ['span 6', 'span 6', 'span 3'],
          }}
        >
          <Text as="h3" sx={headerStyle}>
            Company
          </Text>
          <Box as="ul" variant="styles.ul.unstyled">
            <li>
              <Link href="/about" sx={linkStyle}>
                About
              </Link>
            </li>
            <li>
              <Link href="/press" sx={linkStyle}>
                Press
              </Link>
            </li>
            <li>
              <Link href="/pricing" sx={linkStyle}>
                Pricing
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/standardresume"
                sx={linkStyle}
                external
              >
                Twitter
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/standard-resume-builder"
                sx={linkStyle}
                external
              >
                LinkedIn
              </Link>
            </li>
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: ['span 6', 'span 6', 'span 3'],
          }}
        >
          <Text as="h3" sx={headerStyle}>
            Support
          </Text>
          <Box as="ul" variant="styles.ul.unstyled">
            <li>
              <Link href="/changelog" sx={linkStyle}>
                Changelog
              </Link>
            </li>
            <li>
              <Link href="/contact" sx={linkStyle}>
                Contact
              </Link>
            </li>
            <li>
              <Link href="/frequently-asked-questions" sx={linkStyle}>
                FAQ
              </Link>
            </li>
            <li>
              <Link href="/terms-of-use" sx={linkStyle}>
                Terms
              </Link>
            </li>
            <li>
              <Link href="/privacy-policy" sx={linkStyle}>
                Privacy
              </Link>
            </li>
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: ['span 6', 'span 6', 'span 3'],
          }}
        >
          <Text as="h3" sx={headerStyle}>
            Resumes
          </Text>
          <Box as="ul" variant="styles.ul.unstyled">
            <li>
              <Link href="/resume-templates" sx={linkStyle}>
                Resume Templates
              </Link>
            </li>
            <li>
              <Link href="/resume-builder" sx={linkStyle}>
                Resume Builder
              </Link>
            </li>
            <li>
              <Link href="/linkedin-resume-builder" sx={linkStyle}>
                LinkedIn Resume Builder
              </Link>
            </li>
            <li>
              <Link href="/examples" sx={linkStyle}>
                Resume Examples
              </Link>
            </li>
            <li>
              <Link href="/resources" sx={linkStyle}>
                Resume Resources
              </Link>
            </li>
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: ['span 6', 'span 6', 'span 3'],
          }}
        >
          <Text as="h3" sx={headerStyle}>
            Remote Jobs
          </Text>
          <Box as="ul" variant="styles.ul.unstyled">
            <li>
              <Link href="/remote-jobs" sx={linkStyle}>
                Remote Tech Jobs
              </Link>
            </li>
            <li>
              <Link href="/remote-jobs/engineering" sx={linkStyle}>
                Engineering Jobs
              </Link>
            </li>
            <li>
              <Link href="/remote-jobs/design" sx={linkStyle}>
                Design Jobs
              </Link>
            </li>
            <li>
              <Link href="/remote-jobs/product" sx={linkStyle}>
                Product Jobs
              </Link>
            </li>
            <li>
              <Link href="/remote-jobs/marketing" sx={linkStyle}>
                Marketing Jobs
              </Link>
            </li>
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: 'span 12',
            display: 'flex',
            alignItems: 'center',
            mt: [32, 64],
            width: '100%',
          }}
        >
          <Logo />
        </Box>
      </Box>
    </Box>
  );
}
