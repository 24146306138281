import { useRef, useState } from 'react';
import { Box, Button } from 'theme-ui';
import { CrossIcon, MenuIcon } from 'components/ui/icons';
import { Link, LinkButton } from 'components/ui/links';
import Logo from 'components/ui/Logo';
import { useResumeVerb } from 'context/resume-verb';

export default function Header({
  sticky = true,
  minimal = false,
  dark = false,
}: {
  sticky?: boolean;
  minimal?: boolean;
  dark?: boolean;
}) {
  const { rVerbCap } = useResumeVerb();
  const [open, setOpen] = useState(false);
  const headerEl = useRef(null);

  function toggleMenu() {
    // Assure the header is at the top of the page. This is needed for
    // when a banner is displayed above the header.
    const { top } = headerEl.current.getBoundingClientRect();
    if (top !== 0) {
      headerEl.current.scrollIntoView();
    }
    setOpen(!open);
  }

  return (
    <Box
      as="header"
      id="page-header"
      ref={headerEl}
      sx={{
        position: sticky ? 'sticky' : 'static',
        top: 0,
        py: 13,
        backgroundColor: dark ? 'buttonGray' : ['background', 'background80'],
        zIndex: 'header',
        backdropFilter: [null, 'saturate(180%) blur(30px)'],
      }}
    >
      <Box
        variant="layout.gridContent"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Logo />
        </Box>
        <Box as="nav">
          <Button
            variant="icon"
            sx={{ display: ['block', 'none'] }}
            onClick={toggleMenu}
            title="Menu button"
          >
            {open ? <CrossIcon /> : <MenuIcon />}
          </Button>
          <Box
            sx={{
              display: open ? 'flex' : ['none', 'flex'],
              zIndex: open ? 'drawer' : 'auto',
              alignItems: ['flex-start', 'center'],
              justifyContent: ['flex-start', 'flex-end'],
              flexDirection: ['column', 'row'],
              position: ['fixed', 'relative'],
              top: [80, 'auto'],
              right: [0, 'auto'],
              left: [0, 'auto'],
              bottom: [0, 'auto'],
              backgroundColor: ['background', 'transparent'],
              pt: [24, 0],
              px: [16, 0],
            }}
          >
            <Link
              href="/resume-builder"
              text="emphasis"
              sx={{
                display: ['block', 'none'],
                p: 16,
                mr: 16,
                color: 'text',
              }}
            >
              Resume Builder
            </Link>
            <Link
              href="/linkedin-resume-builder"
              text="emphasis"
              sx={{
                display: ['block', 'none'],
                p: 16,
                mr: 16,
                color: 'text',
              }}
            >
              LinkedIn Resume Builder
            </Link>
            <Link
              href="/resources"
              text="emphasis"
              sx={{
                display: ['block', 'none'],
                p: 16,
                mr: 16,
                color: 'text',
              }}
            >
              Resume Writing Guides
            </Link>
            {!minimal && (
              <>
                <Link
                  href="/resume-templates"
                  text="emphasis"
                  sx={{
                    p: 16,
                    mr: 16,
                    color: 'text',
                  }}
                >
                  Templates
                </Link>
                <Link
                  href="/examples"
                  text="emphasis"
                  sx={{
                    p: 16,
                    mr: 16,
                    color: 'text',
                  }}
                >
                  Examples
                </Link>
                <Link
                  href="/remote-jobs"
                  text="emphasis"
                  sx={{
                    p: 16,
                    mr: 16,
                    color: 'text',
                  }}
                >
                  Jobs
                </Link>
              </>
            )}
            <Link
              href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
              text="emphasis"
              external
              sx={{
                p: 16,
                mr: 16,
                mb: [16, 0],
                color: 'text',
              }}
            >
              Login
            </Link>
            {dark ? (
              <LinkButton
                href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
                sx={{
                  color: 'sWhite',
                  bg: 'sBrandBlue',
                  flexShrink: 0,
                }}
              >
                {`Create ${rVerbCap}`}
              </LinkButton>
            ) : (
              <LinkButton
                href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
                external
                sx={{
                  ml: [0, 16],
                  mt: [32, 0],
                  flexShrink: 0,
                }}
              >
                {`Create ${rVerbCap}`}
              </LinkButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
